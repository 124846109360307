import { sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
  titleColor: '',
  titleSecondaryColor: '',
  descriptionColor: '',
};

export const HeaderTheme: HeaderThemeType = {
  header: {
    background: {
      light: sectionStyle.background.light,
      soft: sectionStyle.background.soft,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.primarySoft,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      dark: sectionStyle.textColor.soft,
    },
  },
};

export type HeaderThemeType = {
  header: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      dark: string;
    };
  };
};
