type StyleMap = {
  background: Record<string, string>;
  padding: Record<string, string>;
  textColor: Record<string, string>;
  maxWidth: Record<string, string>;
};

export const COLORS = {
  primary: 'blue',
  secondary: 'gray',
  goldDark: 'gold-dark',
  redDark: 'red-dark',
  greenDark: 'green-hard',
};

export const sectionStyle: StyleMap = {
  background: {
    default: 'bg-white',
    primary: `bg-${COLORS.primary}`,
    secondary: `bg-${COLORS.secondary}-light`,
    goldDark: `bg-${COLORS.goldDark}`,
    redDark: `bg-${COLORS.redDark}`,
    greenDark: `bg-${COLORS.greenDark}`,

    light: 'bg-white',
    gradient: 'bg-gradient-to-r from-blue-700 to-blue-500',
    soft: `bg-${COLORS.secondary}-light`,
    dark: `bg-${COLORS.primary}-900`,
    grayDark: `bg-${COLORS.secondary}-dark`,
    primarySoft: `text-${COLORS.primary}-soft`,
  },

  padding: {
    paddingTop: 'pt',
    paddingBottom: 'pb',
    paddingLeft: 'pl',
    paddingRight: 'pr',
  },

  textColor: {
    default: `text-${COLORS.secondary}-dark`,
    primary: `text-${COLORS.primary}`,
    secondary: `text-${COLORS.secondary}`,
    light: 'text-white',
    soft: `text-${COLORS.secondary}-300`,
    grayMedium: `text-${COLORS.secondary}-strong`,
    grayInter: `text-${COLORS.secondary}-600`,
    primarySoft: `text-${COLORS.primary}-300`,
    blueDark: `text-${COLORS.primary}-dark`,
    blueSoft: `text-${COLORS.primary}-300`,
    blueLight: `text-${COLORS.primary}-200`,
    blueStrong: `text-blue-800`,
    mint: 'text-mint',
    aqua: 'text-aqua',
    supernova: 'text-supernova',
    clementine: 'text-clementine',
    purple: 'text-purple',
    rose: 'text-rose',
  },

  maxWidth: {
    default: 'max-w-7xl',
    sm: 'max-w-2xl',
    md: 'max-w-4xl',
    lg: 'max-w-6xl',
    full: 'max-w-full',
  },
};

export const sizeTheme: Record<string, string> = {
  medium: 'q1',
  big: 'h3',
  small: 'text-sm',
};

export const headingSizeTheme: Record<string, string> = {
  h1: 'text-6xl',
  h2: 'text-5xl',
  h3: 'text-4xl',
  h4: 'text-3xl',
  h5: 'text-xl',
  t2: 'text-title2',
};

export const bodyCopySizeTheme: Record<string, string> = {
  b1: 'text-b1',
  b2: 'text-b2',
  b3: 'text-b3',
  q1: 'text-q1',
  body3: 'text-body3',
};

export const iconTheme: Record<string, string> = {
  soft: `bg-gray-medium ${sectionStyle.textColor.primary}`,
  basic: `${sectionStyle.background.primary} ${sectionStyle.textColor.light}`,
  dark: `${sectionStyle.background.dark} ${sectionStyle.textColor.light}`,
  light: `${sectionStyle.background.light} ${sectionStyle.textColor.blueDark}`,
  linear: `${sectionStyle.background.light} ${sectionStyle.textColor.primary} border-2 border-${COLORS.primary}`,
  mint: `${sectionStyle.background.dark} ${sectionStyle.textColor.mint}`,
  aqua: `${sectionStyle.background.dark} ${sectionStyle.textColor.aqua}`,
  supernova: `${sectionStyle.background.dark} ${sectionStyle.textColor.supernova}`,
  clementine: `${sectionStyle.background.dark} ${sectionStyle.textColor.clementine}`,
  purple: `${sectionStyle.background.dark} ${sectionStyle.textColor.purple}`,
  rose: `${sectionStyle.background.dark} ${sectionStyle.textColor.rose}`,
  features: `${sectionStyle.background.dark} ${sectionStyle.textColor.primarySoft}`,
  companies: `${sectionStyle.background.primary}-50 ${sectionStyle.textColor.primary}`,
  resources: `bg-transparent ${sectionStyle.textColor.primary}`,
  darkMode: `bg-gray-800 text-white`,
};

export const getThemeStyles = (theme: Record<string, Record<string, string>>, themeStrapi: string) => {
  const themeArray = Object.keys(theme).map((key) => {
    return {
      [key]: theme[key][themeStrapi],
    };
  });

  return themeArray.reduce((acc, attr) => ({ ...acc, ...attr }), {});
};
