import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  bodyCopySizeTheme as BODY_COPY_SIZE,
  getThemeStyles,
  headingSizeTheme as HEADING_SIZE,
} from 'holded/lib/styles';
import { getCustomTag, getCustomTagNext, isDefault } from 'holded/lib/utils';
import { Header1Section } from 'holded/modules/cms/domain/components/header';
import { defaultThemeValues, HeaderTheme } from 'holded/modules/cms/ui/sections/header/HeaderTheme';

const THEME_WITH_TITLE2: Record<Header1Section['background'], Record<string, string>> = {
  light: {
    background: 'bg-white',
    subtitleColor: 'text-[#1963EF]',
  },
  soft: {
    background: 'bg-[#F9FAFB]',
    subtitleColor: 'text-[#1963EF]',
  },
  dark: {
    background: 'bg-[#111827]',
    subtitleColor: 'text-[#8DB3F7]',
  },
};

const Header1 = ({ title, titleSecondary, description, background, customTag }: Header1Section) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);
  const router = useRouter();
  const { h1 } = router.query;

  const CustomTitle = getCustomTag('h1', customTag?.headingTag);
  const CustomTitleSecondary = getCustomTagNext(CustomTitle);
  const defaultHeadingSize = isDefault(customTag?.headingSize);
  const defaultBodySize = isDefault(customTag?.bodyCopySize);
  const headingSize = `text-h4 md:${HEADING_SIZE[customTag?.headingSize ?? 'default']}`;

  const themeWithTitle2 = customTag?.headingSize == 't2' ? THEME_WITH_TITLE2[background] : null;

  useEffect(() => {
    const themeStyles = getThemeStyles(HeaderTheme.header, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);
  return (
    <div className={`${themeWithTitle2?.background ?? themeValues.background}`}>
      <div className={`max-w-4xl mx-auto py-16 px-4 sm:py-6 sm:px-6 md:py-20 lg:px-8`}>
        <div className="text-center">
          {titleSecondary && (
            <CustomTitleSecondary
              className={`${
                customTag?.headingSize == 't2' ? 'text-subtitle4' : 'text-b3 leading-6 tracking-wide font-semibold'
              } ${themeWithTitle2?.subtitleColor ?? themeValues.titleSecondaryColor} uppercase mb-2`}
            >
              {titleSecondary}
            </CustomTitleSecondary>
          )}
          {title && (
            <CustomTitle
              className={`${defaultHeadingSize ? 'text-h4 md:text-h1' : headingSize} ${
                themeValues.titleColor
              } font-extrabold sm:tracking-tight`}
            >
              {CustomTitle == 'h1' && h1 ? h1 : title}
            </CustomTitle>
          )}
          {description && (
            <p
              className={`max-w-xl mt-4 mx-auto ${
                defaultBodySize ? 'text-b1' : BODY_COPY_SIZE[customTag?.bodyCopySize ?? 'default']
              } ${themeValues.descriptionColor}`}
            >
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header1;
